import React, { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../App/core/notification/notification.slice';

import ConfirmationDialog from '../../../../App/app/common/modals/components/ConfirmationDialog';
import { TableViewLayout } from '../../../../App/app/common/layout/components';
import PlanningTableToolbar from './PlanningTableToolbar';
import PlanningTable from './PlanningTable';
import { deletePlanning, addBonsFromPlanning } from '../../../Domain/Planning/use-cases';
import { PlanningDuplicateDialog } from '../common';
import useDocumentTitle from '../../../../_config/react/useDocumentTitle';
import { add, format, parse, sub } from 'date-fns';
import { dateFormat, exportToXLSX } from '../../../../App/app/common/_helpers';
function PlanningList() {

    useDocumentTitle(`MTTP - Planning`);

    const dispatch = useAppDispatch();
    const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState<EntityId[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isConfirmDeleteButtonLoading, setIsConfirmDeleteButtonLoading] = useState(false);

    const handleDialog = () => setIsDialogOpen(!isDialogOpen);

    const onConfirmDialog = async () => {
        setIsConfirmDeleteButtonLoading(true);
        dispatch(deletePlanning(selectedRows))
            .then(() => {
                dispatch(setSuccess(`Les données ont bien été supprimées`));
                setIsDialogOpen(false);
            })
            .finally(() => {
                setIsConfirmDeleteButtonLoading(false)
                setSelectedRows([])
            })
    };

    const onCreateBons = async () => {
        const res: any = await dispatch(addBonsFromPlanning(selectedRows));

        if ( !res.error ) {
            dispatch(setSuccess(`Les bons ont bien été créés`));
        }
    };

    const onDuplicate = () => {
        setIsDuplicateDialogOpen(true);
    };
    const closeDuplicate = () => {
        setIsDuplicateDialogOpen(false);
    }

    let defaultPlanningDate = new Date();
    defaultPlanningDate.setDate(defaultPlanningDate.getDate() + 1);
    const defaultPlanningDateString = format(defaultPlanningDate, dateFormat);
    return (
        <>
            <TableViewLayout title="Planning">
                <PlanningTableToolbar
                    onClickDeleteMultiple={handleDialog}
                    onCreateBons={onCreateBons}
                    onDuplicate={onDuplicate}
                    hasSelectedRows={selectedRows.length > 0}
                    defaultPlanningDate={defaultPlanningDateString}
                />
                <PlanningTable onSelectRows={setSelectedRows}/>
            </TableViewLayout>
            <ConfirmationDialog
                open={isDialogOpen}
                title="Supprimer"
                contentText={'Êtes-vous sûr de supprimer ces données ?'}
                isButtonLoading={isConfirmDeleteButtonLoading}
                onClose={handleDialog}
                onConfirm={onConfirmDialog}
            />
            <PlanningDuplicateDialog
                planningId={selectedRows}
                open={isDuplicateDialogOpen}
                onClose={closeDuplicate}
                onConfirm={closeDuplicate}
            />
        </>
    );
}

export default PlanningList;
