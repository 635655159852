import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';

import { Stack, Button } from '@mui/material';

import { CustomTable } from '../../../common/custom-table';
import { ColumnEntity } from '../../../common/custom-table/Table.entities';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import PaiementTableActionsCell from './PaiementTableActionsCell';
import { selectAllPaymentTypes } from '../../../../core/payment-type/selectors';
import { retrieveAllPaymentType } from '../../../../core/payment-type/use-cases';
import PaiementTableAddDialog from './PaiementTableAddDialog';
import { PaymentType } from '../../../../core/payment-type/entities';
import useDocumentTitle from '../../../../../_config/react/useDocumentTitle';

const PaiementTable = () => {

  useDocumentTitle('MTTP - Paiement');
  
  const [isLoading, setIsLoading] = useState(true);
  const [typeDialogOpen, setTypeDialogOpen] = useState(false);
  const [typeId, setTypeId] = useState('');
  const [initialValues, setInitialValues] = useState({
    code: '',
    wording: '',
    type: '',
  });

  const dispatch = useAppDispatch();
  const paymentTypes = useSelector(selectAllPaymentTypes);

  useEffect(() => {
    dispatch(retrieveAllPaymentType()).then(() => setIsLoading(false));

    return () => {
      setIsLoading(true);
    };
  }, [dispatch, setIsLoading]);

  const onRowDoubleClick = (values: PaymentType) => {
    const { _id, ...otherProps } = values;
    setTypeId(values._id);
    setInitialValues(otherProps);

    setTypeDialogOpen(true);
  };

  const onSelectRows = () => {};

  const onClickEdit = (id: EntityId) => {
    let typeById = paymentTypes.find((t) => t._id === id);
    if (typeById) {
      const { _id, ...otherProps } = typeById;
      setTypeId(_id);
      setInitialValues(otherProps);
    }

    setTypeDialogOpen(true);
  };

  const onCloseTypeDialog = () => {
    setTypeId('');
    setTypeDialogOpen(false);

    setTimeout(() => {
      setInitialValues({ code: '', wording: '', type: '' });
    }, 200);
  };

  const columns: ColumnEntity[] = [
    { field: 'code', headerName: 'Code', width: 120, sortable: true, resizable: true },
    { field: 'wording', headerName: 'Libellé', width: 320, sortable: true, resizable: true },
    { field: 'type', headerName: 'Type', width: 70, sortable: true, resizable: true },
  ];

  return (
    <>
      <Stack direction="row" gap={6}>
        <CustomTable
          data={paymentTypes}
          columns={columns}
          loading={isLoading}
          hover={false}
          width={600}
          onRowDoubleClick={onRowDoubleClick}
          onSelectRows={onSelectRows}
          renderActionCell={(rowData, measuredRef) => (
            <PaiementTableActionsCell id={rowData._id} measuredRef={measuredRef} onClickEdit={onClickEdit} />
          )}
        />
        <Stack direction="column" gap={2}>
          <Button variant="outlined" color="primary" onClick={() => setTypeDialogOpen(true)}>
            Ajouter
          </Button>
        </Stack>
      </Stack>
      <PaiementTableAddDialog
        open={typeDialogOpen}
        onCloseDialog={onCloseTypeDialog}
        initialValues={initialValues}
        typeId={typeId}
      />
    </>
  );
};

export default PaiementTable;
