import { createSelector, EntityId } from '@reduxjs/toolkit';
import { factureEntityAdapter } from './facture.slice';
import { RootState } from '../../../_config/redux';
import { BillableBonsGroupByCompany, IBillableBon } from "../Model/Bon";

import { BonEntity } from "../../../Location/Domain/Bons/entities";
import { selectBillableBons } from "../../../Location/Domain/Bons/selectors";

const factureSelector = factureEntityAdapter.getSelectors<RootState>((state) => state.factures);

export const selectFactureById = (id: EntityId) => (state: RootState) => factureSelector.selectById(state, id);

export const selectAllFactures = (state: RootState) => factureSelector.selectAll(state);

export const selectAvoir = (state: RootState) => state.factures.avoir;

export const selectAllRelances = (state: RootState) => state.factures.relance;

export const selectFilters = (state: RootState) => state.factures.filters;


export const selectSortedFactures = (state: RootState) => factureSelector
    .selectAll(state)
;


const selectPendingBillableBon = (state: RootState) => state.factures
    .bonsDeCommandes
    .reduce<BillableBonsGroupByCompany[]>((commandes, currentBonFournisseur) => {
            if ( commandes.findIndex(c => c.companyId === currentBonFournisseur.entreprise.id) === -1 ) commandes.push({
                companyId: currentBonFournisseur.entreprise.id,
                companyName: currentBonFournisseur.entreprise.raisonSociale,
                type: "fournisseur",
                chantiers: []
            })

            const companyIndex = commandes.findIndex(c => c.companyId === currentBonFournisseur.entreprise.id);
            if ( commandes[companyIndex].chantiers.findIndex(c => c.name === currentBonFournisseur.chantier.denomination) === -1 )
                commandes[companyIndex].chantiers.push({
                    id: currentBonFournisseur.chantier.id,
                    name: currentBonFournisseur.chantier.denomination,
                    bons: []
                })

            const indexChantier = commandes[companyIndex].chantiers.findIndex(c => c.name === currentBonFournisseur.chantier.denomination);
            commandes[companyIndex].chantiers[indexChantier].bons.push({
                id: currentBonFournisseur.id,
                client: currentBonFournisseur.chantier?.client,
                numeroBon: currentBonFournisseur.info.numeroBon,
                referenceClient: currentBonFournisseur.info.referenceClient,
                quantity: currentBonFournisseur.article.quantity.value,
                unite: currentBonFournisseur.article.quantity.unite,
                prixUnitaire: currentBonFournisseur.article.prixUnitaire.value,
                montantHT: currentBonFournisseur.montantHT.value,
                fraisAutoroute: currentBonFournisseur.fraisAutoroute.value,
                dateOfPrestation: currentBonFournisseur.date,
                type: currentBonFournisseur.article.type,
                articleLabel: currentBonFournisseur.article.denomination
            })
            return commandes;
        }, []
    );

// SHOULD NOT DEPEND ON BON of Location Context
// |--> will use a specific use-case to retreive billables bons
export const selectBonsForFactureGroupByCompany = (state: RootState): BillableBonsGroupByCompany[] => {
    return selectBillableBons(state)
        .filter(bon => bon.status === "SIGNED")
        .reduce<BillableBonsGroupByCompany[]>((companies, currentBon) => {
            if ( currentBon.ids?.client != null && !companies.find(c => c.companyId === currentBon.ids?.client) ) {
                companies.push({
                    companyName: currentBon.client,
                    companyId: currentBon.ids.client,
                    type: 'client',
                    chantiers: []
                })
            }
            const existingCompany = companies.findIndex(c => c.companyId === currentBon.ids?.client);
            const chantierOfcurrentBon = companies[existingCompany].chantiers.findIndex(c => c.name === currentBon.chantier);

            if ( chantierOfcurrentBon !== -1 ) {
                companies[existingCompany].chantiers[chantierOfcurrentBon].bons.push(mapBonToBillableBonView(currentBon));
            } else {
                companies[existingCompany].chantiers.push({
                    id: currentBon.ids?.chantier as EntityId,
                    name: currentBon.chantier,
                    bons: [mapBonToBillableBonView(currentBon)]
                })
            }
            return companies;
        }, [])
}

export const selectBonsForFacturesAndCommandes = (type?: 'client' | 'fournisseur' | '') => createSelector(
    selectBonsForFactureGroupByCompany,
    selectPendingBillableBon,
    (bons, bonsDeCommande) => {
        return Boolean(type)
            ? [...bons, ...bonsDeCommande].filter(b => b.type === type)
            : [...bons, ...bonsDeCommande]
    }
)

export const selectRemainingInvoiceAmountOfFacture = (factureId: EntityId) => createSelector(
    selectFactureById(factureId),
    (facture) => {
        if ( !facture || facture.totalTTC == null ) return 0;
        return facture.totalTTC - facture.reglement.montant
    }
)


function mapBonToBillableBonView(bon: BonEntity): IBillableBon {
    return {
        articleLabel: bon.article,
        type: bon.type,
        dateOfPrestation: bon.date,
        id: bon.id,
        montantHT: bon.prixVenteHT * bon.quantite,
        numeroBon: bon.bonNumber,
        prixUnitaire: bon.prixVenteHT,
        quantity: bon.quantite,
        referenceClient: bon.referenceClient,
        unite: bon.unite,
        fraisAutoroute: bon.fraisAutoroute,
        fournisseur: bon?.fournisseur,
    }
}