import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { add, format, parse, sub } from 'date-fns';
import { Button, IconButton, TextField, InputAdornment, Stack, Tooltip } from '@mui/material';
import {
    DeleteRounded as DeleteIcon,
    Search as SearchIcon,
    AddRounded as AddIcon,
    ChevronLeft,
    ChevronRight,
    FileDownload,
    Receipt,
    CopyAll,
} from '@mui/icons-material';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { RootState } from '../../../../_config/redux';


import { updateSearch, updateDate } from '../../../Domain/Planning/planning.slice';
import { selectAllPlanning } from '../../../Domain/Planning/selectors';
import { dateFormat, exportToXLSX } from '../../../../App/app/common/_helpers';
import { DatePickerField } from '../../../../App/app/common/date-picker';
import { CustomTableToolbar } from '../../../../App/app/common/custom-table';

interface Props {
    onClickDeleteMultiple(event: React.MouseEvent): void;
    onDuplicate(event: React.MouseEvent): void;
    onCreateBons(event: React.MouseEvent): void;
    defaultPlanningDate: string;
    hasSelectedRows: boolean;
}

function PlanningTableToolbar({ onClickDeleteMultiple, onDuplicate, onCreateBons, hasSelectedRows, defaultPlanningDate }: Props) {
    let selectedDate = useSelector((state: RootState) => state.planning.date);
    if(defaultPlanningDate != "") {
        selectedDate = defaultPlanningDate;
        defaultPlanningDate = "";
    }
    
    console.log(useSelector((state: RootState) => state.planning)); console.log(selectedDate);
    const [search, setSearch] = useState('');
    const dispatch = useAppDispatch();

    const planning = useSelector(selectAllPlanning);

    useEffect(() => {
        dispatch(updateSearch(search));
    }, [dispatch, search]);

    const onDateUpdate = (field: string, value: string) => {
        if ( value && value !== selectedDate ) dispatch(updateDate(value));
    };

    const onClickDayBefore = () => {
        const dayBefore = sub(parse(selectedDate, dateFormat, new Date()), { days: 1 });
        dispatch(updateDate(format(dayBefore, dateFormat)));
    };

    const onClickDayAfter = () => {
        const dayAfter = add(parse(selectedDate, dateFormat, new Date()), { days: 1 });
        dispatch(updateDate(format(dayAfter, dateFormat)));
    };

    const onClickExport = () => {
        const toExport = planning.map(({ id, contact, ids, ...otherProps }) => otherProps);
        const heading = [
            'Date',
            'Client',
            'Commandé par',
            'Chantier',
            'Fournisseur',
            'Article',
            'Prix Vente HT',
            'Prix Achat HT',
            'Quantité',
            'Unité',
            'Achat HT',
            'Montant HT',
            'Marge',
        ];
        exportToXLSX(toExport, `planning-${selectedDate}`, heading);
    };


    return (
        <CustomTableToolbar>
            <Stack direction="row" alignItems="flex-end" spacing={1}>
                <TextField
                    sx={{ width: 300 }}
                    onChange={(event) => {
                        const { value } = event.target;
                        setSearch(value);
                    }}
                    label="Rechercher..."
                    size="small"
                    variant="outlined"
                    InputProps={{
                        type: 'search',
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
                {/* Calendar + previous/next day buttons */}
                <Stack direction="row" alignItems="flex-end">
                    <Tooltip title="Jour précédent">
                        <IconButton onClick={onClickDayBefore}>
                            <ChevronLeft/>
                        </IconButton>
                    </Tooltip>
                    <DatePickerField
                        sx={{ width: 165 }}
                        variant="outlined"
                        size="small"
                        label="Filtrer par date..."
                        onDateUpdate={onDateUpdate}
                        value={selectedDate}
                    />
                    <Tooltip title="Jour suivant">
                        <IconButton onClick={onClickDayAfter}>
                            <ChevronRight/>
                        </IconButton>
                    </Tooltip>
                </Stack>

                <Button
                    variant="contained"
                    color="primary"
                    disabled={!hasSelectedRows}
                    onClick={onCreateBons}
                    startIcon={<Receipt/>}
                    size="small"
                >
                    Créer bons
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    disabled={!hasSelectedRows}
                    onClick={onDuplicate}
                    startIcon={<CopyAll/>}
                    size="small"
                >
                    Dupliquer
                </Button>


                <Button
                    variant="contained"
                    color="error"
                    disabled={!hasSelectedRows}
                    onClick={onClickDeleteMultiple}
                    startIcon={<DeleteIcon/>}
                    size="small"
                >
                    Supprimer
                </Button>
                <Button variant="contained" color="success" onClick={onClickExport} startIcon={<FileDownload/>}
                        size="small">
                    Exporter
                </Button>
            </Stack>
            <Stack direction="row" spacing={4} alignItems="end">
                <Button component={Link} to="create" variant="contained" color="secondary" startIcon={<AddIcon/>}>
                    Nouvelle Ligne
                </Button>
            </Stack>
        </CustomTableToolbar>
    );
}

export default React.memo(PlanningTableToolbar);
