import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, useFormikContext, FormikContextType, FormikValues } from 'formik';
import { object, string, number, date, setLocale } from 'yup';
import { parse } from 'date-fns';

import { Divider, Grid, MenuItem, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { selectChantierLabelsAndIdsByClientId } from '../../../../App/core/chantier/selectors';
import { selectAllInterlocuteurOfCompany } from '../../../../App/core/interlocuteur/selectors';
import { selectAllLabelsAndIds } from '../../../../App/core/entreprise/selectors';
import { selectChauffeursByCompany } from '../../../../App/core/user/selectors';
import { selectors as articleSelectors } from '../../../../App/core/article';

import { Planning } from '../../../Domain/Planning/entities/planning.entity';
import { bonSelector } from '../../../Domain/Bons';

import PlanningFormPreview from './PlanningFormPreview';
import {
    FormFieldAutocomplete,
    FormFieldLayout,
    FormFieldModalLink,
    FormFieldAutocompleteContext,
} from '../../../../App/app/common/form-field';
import { DatePickerField } from '../../../../App/app/common/date-picker';
import { dateFormat, unites } from '../../../../App/app/common/_helpers';
import { yupFr } from '../../../../App/app/common/i18n';

setLocale(yupFr);
const schema = object().shape({
    client: string().required(),
    chantier: string().required(),
    interlocuteur: string().required(),
    chauffeur: string().notRequired(),
    article: string().required(),
    fournisseur: string().required(),
    unite: string().required(),
    date: date()
        .transform((value, originalValue) => parse(originalValue, dateFormat, new Date()))
        .required()
        .typeError('La date doit être au format dd/mm/yyyy'),
    prixUnitaireAchatHT: number().min(0),
    prixUnitaireVenteHT: number().min(0),
    prixAchatHT: number().min(0).required(),
    prixVenteHT: number().min(0).required(),
    quantite: number().min(0).required(),
    commentaire_interne: string().notRequired(),
    consignes_chauffeur: string().notRequired(),
    evenement: string().notRequired(),
    fraisAutoroute: number().notRequired(),
    confirmedFraisAutoroute: number().notRequired()
});

interface Props {
    initialValues: any
    isButtonLoading: boolean
    defaultValues?: any
    isBon: boolean
    isSigned: boolean

    onSubmit(values: Partial<Planning> & {
        prixUnitaireAchatHT: number,
        prixUnitaireVenteHT: number,
        interlocuteur: string,
        fraisAutoroute?: number,
        confirmedFraisAutoroute?: number
    }): void;
}

function PlanningForm({ initialValues, onSubmit, isButtonLoading, defaultValues, isBon, isSigned }: Props) {
    const [selection, setSelection] = useState<Record<string, string>>({
        client: defaultValues?.client?.id ?? '',
        chantier: defaultValues?.chantier?.id ?? '',
        interlocuteur: defaultValues?.interlocuteur?.id ?? '',
        fournisseur: defaultValues?.fournisseur?.id ?? '',
        chauffeur: defaultValues?.chauffeur?.id ?? '',
        article: defaultValues?.article?.id ?? '',
    });
 
    const entreprises = useSelector(selectAllLabelsAndIds);
    const chantiers = useSelector(selectChantierLabelsAndIdsByClientId(selection.client));
    // const chantiers = useSelector(selectAllChantiersLabelsAndIds);
    const interlocuteurs = useSelector(selectAllInterlocuteurOfCompany(selection.client));
    // 5.5.22: Requirement: no filter by selected fournisseur
    const articles = useSelector(articleSelectors.selectAllArticlesLabelsAndIds);
    const chauffeurs = useSelector(selectChauffeursByCompany(selection.fournisseur));
    // const chauffeurs = useSelector(selectChauffeursLabelsAndIds);

    const createdFromModal: any = useSelector(bonSelector.selectCreatedFromModal);

    const handleInputSelection = (field: { value: string; name: string }) => {
        if ( selection[field.name] !== field.value ) {
            setSelection({ ...selection, [field.name]: field.value });
        }
    };

    useEffect(() => {
        if ( createdFromModal ) {
            setSelection({ ...selection, [createdFromModal.entity]: createdFromModal.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdFromModal]);

    const defaultUnityValue = "journee";

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
            {(props) => {
                const { values, touched, errors, dirty, isValid, handleChange, handleBlur, setFieldValue } = props;
                console.log(values);
                if(isNaN(Number(values.prixVenteHT))) {
                    values.prixVenteHT = Number(initialValues?.prixVenteHT);
                }
                if(isNaN(Number(values.prixAchatHT))) {
                    values.prixAchatHT = Number(initialValues?.prixAchatHT);
                }
                if(isNaN(Number(values.prixUnitaireVenteHT))) {
                    values.prixUnitaireVenteHT = Number(initialValues?.prixVenteHT)/Number(initialValues?.quantite);
                }
                if(isNaN(Number(values.prixUnitaireAchatHT))) {
                    values.prixUnitaireAchatHT = Number(initialValues?.prixAchatHT)/Number(initialValues?.quantite);
                }
                //{values?.prixVenteHT ? values?.prixUnitaireVenteHT : (Number(initialValues?.prixVenteHT)/Number(initialValues?.quantite))
                return (
                    <Form>
                        <Grid container direction="row" spacing={2} p={4} mb={4} columnSpacing={6}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>

                                    {/* Date Picker */}
                                  
                                    <DatePickerField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        label="Date"
                                        placeholder=""
                                        id="Date"
                                        value={values.date}
                                        onDateUpdate={setFieldValue}
                                        onBlur={handleBlur}
                                        disabled={isSigned}
                                        error={!!errors.date && !!touched.date}
                                        name="date"
                                        helperText={touched.date && errors.date}
                                    />
                                  
                                    {/* Chantiers */}
                                    <FormFieldLayout label="Chantier" selection={selection.client}>
                                        <FormFieldAutocomplete
                                            id="chantier"
                                            options={chantiers}
                                            defaultValue={defaultValues.chantier}
                                            emitSelection={handleInputSelection}
                                            inputDisabled={isSigned}
                                            variant="outlined"
                                            required
                                        />
                                        <FormFieldModalLink/>
                                        <PlanningFormPreview entityName="chantier" id={values.chantier}/>
                                    </FormFieldLayout>

                                    {/* Interlocuteurs */}
                                    <FormFieldLayout label="Interlocuteur" selection={selection.client}>
                                        <FormFieldAutocomplete
                                            id="interlocuteur"
                                            options={interlocuteurs}
                                            defaultValue={defaultValues.interlocuteur}
                                            emitSelection={handleInputSelection}
                                            variant="outlined"
                                            inputDisabled={isSigned}
                                            required
                                        />
                                        <FormFieldModalLink/>
                                        <PlanningFormPreview entityName="interlocuteur" id={values.interlocuteur}/>
                                    </FormFieldLayout>
                                  
                               

                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>

                                    {/* Clients */}
                                    <FormFieldLayout label="Client">
                                        <FormFieldAutocomplete
                                            id="client"
                                            options={entreprises}
                                            defaultValue={defaultValues.client}
                                            emitSelection={handleInputSelection}
                                            inputDisabled={isSigned}
                                            variant="outlined"
                                            required
                                        />
                                        <FormFieldModalLink/>
                                        <PlanningFormPreview entityName="client" id={values.client}/>
                                    </FormFieldLayout>

                                    {/* Fournisseurs */}
                                    <FormFieldLayout label="Fournisseur">
                                        <FormFieldAutocomplete
                                            id="fournisseur"
                                            options={entreprises}
                                            defaultValue={defaultValues.fournisseur}
                                            emitSelection={handleInputSelection}
                                            variant="outlined"
                                            inputDisabled={isSigned}
                                            required
                                        />
                                        <FormFieldModalLink/>
                                        <PlanningFormPreview entityName="fournisseur" id={values.fournisseur}/>
                                    </FormFieldLayout>

                                    {/* Chauffeurs */}
                                    <FormFieldLayout label="Chauffeur" selection={selection.fournisseur}>
                                        <FormFieldAutocomplete
                                            id="chauffeur"
                                            options={chauffeurs}
                                            defaultValue={defaultValues.chauffeur}
                                            emitSelection={handleInputSelection}
                                            inputDisabled={isSigned}
                                            variant="outlined"
                                        />
                                        <FormFieldModalLink/>
                                        <PlanningFormPreview entityName="chauffeur" id={values.chauffeur}/>
                                    </FormFieldLayout>

                                </Stack>
                            </Grid>
                        </Grid>
                    
                        <Grid container direction="row" spacing={2} p={4} mb={1} columnSpacing={6} 
                        style={{ backgroundColor: "#f8f8f8", borderTop : "1px solid #cacaca", borderBottom : "1px solid #cacaca" }}>

                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>

                                    {/* Articles */}
                                    <FormFieldLayout label="Article" /*selection={selection.fournisseur}*/>
                                        <FormFieldAutocomplete
                                            id="article"
                                            options={articles}
                                            defaultValue={defaultValues.article}
                                            emitSelection={handleInputSelection}
                                            variant="outlined"
                                            inputDisabled={isSigned}
                                            required
                                        />
                                        <FormFieldModalLink/>
                                        <PlanningFormPreview entityName="article" id={values.article}/>
                                    </FormFieldLayout>

                                    {/* Quantite */}
                                    <TextField
                                        fullWidth
                                        required
                                        label="Quantité"
                                        placeholder="Quantité *"
                                        id="quantite"
                                        value={values.quantite}
                                        onChange={handleChange}
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={!!errors.quantite && !!touched.quantite}
                                        name="quantite"
                                        helperText={touched.quantite && errors.quantite}
                                        type="number"
                                        disabled={isSigned}
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    {/* Unite */}
                                    <Field
                                        component={TextField}
                                        select
                                        fullWidth
                                        required
                                        label="Unité"
                                        placeholder="Unité *"
                                        id="unite"
                                        name="unite"
                                        value={values.unite ? values.unite : defaultUnityValue}
                                        onChange={handleChange}
                                        inputProps={{ name: 'unite', id: 'unite' }}
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={!!errors.unite && !!touched.unite}
                                        helperText={touched.unite && errors.unite}
                                        disabled={isSigned}
                                        InputLabelProps={{ shrink: true }}
                                    >
                                        {unites.map((unite) => (
                                            <MenuItem key={unite.value} value={unite.value}>
                                                {unite.label}
                                            </MenuItem>
                                        ))}
                                    </Field>



                                 
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                                                   
                                    {/*Prix unitaire*/}
                                    <TextField
                                        fullWidth
                                        label="Prix Achat Unitaire"
                                        placeholder="Prix Achat Unitaire"
                                        id='prixUnitaireAchatHT'
                                        value={values?.prixUnitaireAchatHT ? Number(values?.prixUnitaireAchatHT) : (Number(initialValues?.prixAchatHT)/Number(initialValues?.quantite))}
                                        //value={values?.prixUnitaireAchatHT} // ? values?.prixUnitaireAchatHT : initialValues?.prixAchatHT}
                                        onChange={handleChange}
                                        type="number"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                                        disabled={isSigned}
                                        InputLabelProps={{ shrink: true }}
                                    />


                                    {/* Prix Achat */}
                                    <TextField
                                        fullWidth
                                        required
                                        label="Prix Achat HT"
                                        placeholder="Prix Achat HT *"
                                        id="prixAchatHT"
                                        value={values.prixAchatHT ? values.prixAchatHT : initialValues?.prixAchatHT}
                                        onChange={handleChange}
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={!!errors.prixAchatHT && !!touched.prixAchatHT}
                                        name="prixAchatHT"
                                        helperText={touched.prixAchatHT && errors.prixAchatHT}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                                        disabled
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    {/*Prix Vente unitaire */}
                                    <TextField
                                        fullWidth
                                        label="Prix Vente Unitaire"
                                        placeholder="Prix Vente Unitaire"
                                        id='prixUnitaireVenteHT'
                                        value={values?.prixUnitaireVenteHT ? Number(values?.prixUnitaireVenteHT) : (Number(initialValues?.prixVenteHT)/Number(initialValues?.quantite))}
                                        onChange={handleChange}
                                        type="number"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                                        disabled={isSigned}
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    {/* Prix vente */}
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        label="Prix Vente HT"
                                        placeholder="Prix Vente HT *"
                                        id="prixVenteHT"
                                        value={values.prixVenteHT ? Number(values.prixVenteHT) : Number(initialValues?.prixVenteHT)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.prixVenteHT && !!touched.prixVenteHT}
                                        name="prixVenteHT"
                                        helperText={touched.prixVenteHT && errors.prixVenteHT}
                                        disabled
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                
                                </Stack>
                            </Grid>

                        </Grid>
                   
                    <Grid container direction="row" spacing={2} p={4} mb={1} columnSpacing={6}>
                            <Grid item xs={12} md={6}>

                            <Stack spacing={2}>
                                    {/* Consignes */}
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        label="Consignes"
                                        id="consignes_chauffeur"
                                        value={values.consignes_chauffeur}
                                        onChange={handleChange}
                                        name="consignes_chauffeur"
                                    />

                                 
                                    {/* Commentaire interne */}
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        label="Commentaire interne"
                                        id="commentaire_interne"
                                        value={values.commentaire_interne}
                                        onChange={handleChange}
                                        name="commentaire_interne"
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <Stack spacing={2}>
                            {isBon ?
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Frais d'Autoroute"
                                            id="fraisAutoroute"
                                            value={values.fraisAutoroute}
                                            defaultValue={defaultValues.fraisAutoroute}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.fraisAutoroute && !!touched.fraisAutoroute}
                                            name="fraisAutoroute"
                                            disabled={isSigned}
                                            helperText={touched.fraisAutoroute && errors.fraisAutoroute}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                                        /> : null}

                                    {isBon && isSigned ?
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Confirmation Frais d'Autoroute"
                                            id="confirmedFraisAutoroute"
                                            value={values.confirmedFraisAutoroute}
                                            defaultValue={defaultValues.confirmedFraisAutoroute}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.confirmedFraisAutoroute && !!touched.confirmedFraisAutoroute}
                                            name="confirmedFraisAutoroute"
                                            helperText={touched.confirmedFraisAutoroute && errors.confirmedFraisAutoroute}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*.?[0-9]*' }}
                                        /> : null}
                                    {/* Evenement */}
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        label="Evenements"
                                        id="evenement"
                                        value={values.evenement}
                                        onChange={handleChange}
                                        name="evenement"
                                    />
                                    </Stack>
                            </Grid>
                    </Grid>
                    <Divider />
                    <Grid container direction="row" spacing={2} p={4} mb={4} columnSpacing={6}>
                        <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2}>
                                <LoadingButton
                                    loading={isButtonLoading}
                                    variant="contained"
                                    type="submit"
                                    //disabled={!dirty || !isValid}
                                >
                                    Enregistrer
                                </LoadingButton>
                        </Grid>
                    </Grid>
                        <TextFieldContext/>
                        <FormFieldAutocompleteContext/>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default PlanningForm;

const DEFAULT_QUANTITY = 1;
const DEFAULT_UNIT = "journee";

function TextFieldContext() {
    const { values: formikValues, setFieldValue }: FormikContextType<FormikValues> = useFormikContext();
    const selectedArticle = useSelector(
        articleSelectors.selectArticleById((formikValues as { article: string }).article)
    );

    useEffect(() => {
        if ( Boolean(formikValues.article) ) {
            if ( !formikValues.quantite ) setFieldValue('quantite', DEFAULT_QUANTITY);
            if ( !formikValues.unite ) setFieldValue('unite', DEFAULT_UNIT);
        }
    }, [formikValues.article, formikValues.quantite, formikValues.unite, setFieldValue])


    useEffect(() => {
        if ( ['journee', 'heure'].includes(formikValues.unite) && selectedArticle != null ) {
            const prixHT = formikValues.unite === "heure" ? selectedArticle.prixHT / 8 : selectedArticle.prixHT
            const prixVenteHT = formikValues.unite === "heure" ? selectedArticle.prixVenteHT / 8 : selectedArticle.prixVenteHT
            setFieldValue('prixUnitaireAchatHT', prixHT);
            setFieldValue('prixUnitaireVenteHT', prixVenteHT);
        }
    }, [formikValues.unite, setFieldValue, selectedArticle])


    useEffect(() => {
        console.log('use Effect price : ' + formikValues.prixUnitaireAchatHT + " " + formikValues.quantite);
        console.log('use Effect price : ' +  Number(formikValues.prixUnitaireAchatHT) * Number(formikValues.quantite));
        setFieldValue('prixAchatHT', Number(formikValues.prixUnitaireAchatHT) * Number(formikValues.quantite));
        setFieldValue('prixVenteHT', Number(formikValues.prixUnitaireVenteHT) * Number(formikValues.quantite));
    }, [
        formikValues.quantite,
        formikValues.prixUnitaireAchatHT,
        formikValues.prixUnitaireVenteHT,
        setFieldValue
    ])

    return null;
}
