import React from 'react';

// import { generateFile } from '../../../../App/core/facture/use-cases';

import { Stack, IconButton, Tooltip, Box } from '@mui/material';
import { Description, Edit, /*Euro, PriorityHigh, TaskAlt,*/ ForwardToInbox, Delete } from '@mui/icons-material';
//import { Facture } from '../../../Domain/Model';
import { useCases } from "../../../Domain/Application";
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import BonDeCommandeActionsCell from "../bondecommande/bdc-list/BonDeCommandeActionsCell";
import { generateAvoirFile } from '../../../Domain/Application/use-cases';
import { EntityId } from '@reduxjs/toolkit';

interface Props {
    rowData: any;
    measuredRef: any;
    handleDialogs(buttonId: string, rowData: any): void;
}

const AvoirTableActionsCell = ({ rowData, measuredRef, handleDialogs }: Props) => {
    /* Display Invoice */
    const dispatch = useAppDispatch()
console.log(rowData);

    if ( rowData.type === 'fournisseur' ) {
        return <BonDeCommandeActionsCell
            rowData={rowData} measuredRef={measuredRef}
            handleDialogs={handleDialogs}
        />
    }


    const onClickDisplayInvoice = async () => {
        const res = await dispatch(useCases.generateFile(rowData.ref_facture.id)).unwrap();
        const fileURL = window.URL.createObjectURL(res);
        window.open(fileURL);
    };

    const onClickButton = ({ currentTarget: { id: buttonId } }: { currentTarget: { id: string } }) =>
        handleDialogs(buttonId, rowData);

    const onClickDisplayAvoir = async (avoirId: EntityId, factureId: EntityId) => {
            const res = await dispatch(generateAvoirFile(avoirId, factureId)).unwrap()
            window.open(window.URL.createObjectURL(res));
    };

/* */
    return (
        <Stack direction="row">
            {/* Buttons */}

            <Box ref={measuredRef}>
                {/* Editing is available on invoices with CREATED status */}

                <Tooltip title="Afficher l'avoir">
                    <IconButton aria-label="avoir" id="avoir" color="error" size="small"
                            onClick={() => {
                                onClickDisplayAvoir(rowData._id, rowData._id)
                            }}  >
                        <Description/>
                    </IconButton>
                </Tooltip>


                <Tooltip title="Afficher facture">
                    <IconButton aria-label="facture" id="facture" color="primary" size="small"
                                onClick={onClickDisplayInvoice}>
                        <Description/>
                    </IconButton>
                </Tooltip>


                {rowData.status === 'CREATED' && (
                    <>
                        <Tooltip title="Editer">
                            <IconButton aria-label="editer" id="edit" color="primary" size="small"
                                        onClick={onClickButton}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Emettre l'avoir">
                            <IconButton aria-label="emettre" id="emit" color="primary" size="small"
                                        onClick={onClickButton}>
                                <ForwardToInbox/>
                            </IconButton>
                        </Tooltip>
                    {rowData.numeroFacture === "Brouillon" && (
                        <Tooltip title="Supprimer le brouillon">
                            <IconButton aria-label="supprimer" id="delete" color="error" size="small"
                                        onClick={onClickButton}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    )}
                        

                        {/* Je triche avec un bouton vide pour que la taille de la div contenant les boutons soit correct */}
                        {/* Sans ca, la div prend que 3 boutons au lieu de 4 */}
                        {/* Pas ouf comme solution mais c'est rapide, je trouve autre chose plus propre et dynamique si j'ai le temps */}
                        <IconButton disabled={true} size="small">
                            <Box width={'40px'}/>
                        </IconButton>
                    </>
                )}
            </Box>
        </Stack>
    );
};

export default AvoirTableActionsCell;
