import { useSelector } from 'react-redux';

import PageHeaderBar from '../../../../App/app/common/layout/components/PageHeaderBar';
import { ViewCard } from '../../../../App/app/common/layout/components';
import { CustomTable, CustomTableToolbar } from '../../../../App/app/common/custom-table';
import { useAppDispatch } from "../../../../_config/react/useAppDispatch";
import { retrieveAllFactures,} from "../../../Domain/Application/use-cases";
import { updateDate } from "../../../Domain/Application/facture.slice";
import { selectAllFactures } from "../../../Domain/Application/selectors";
import { Stack } from '@mui/material';
import { DateRangeField } from '../../../../App/app/common/date-picker';
import { RootState } from '../../../../_config/redux';
import { useCallback, useEffect } from 'react';
import { validateDates } from '../../../../App/app/common/_helpers';
import { ColumnEntity } from '../../../../App/app/common/custom-table/Table.entities';
import { EntityId } from "@reduxjs/toolkit";
interface Props {
  onSelectRows(ids: EntityId[]): void;
}

function ListeEcritureTable({ onSelectRows }: Props) {
   
    const dispatch = useAppDispatch();
    const selectedDates = useSelector((state: RootState) => state.factures.dates);
    const status = useSelector((state: RootState) => state.factures.status);
    const factures = useSelector(selectAllFactures);


    const loadFactures = useCallback(
        function () {
          try {
            const { fromDate, toDate } = validateDates(selectedDates);
            dispatch(retrieveAllFactures({ status, from: fromDate, to: toDate }))
              .unwrap()
              .then(() => {
                 
              });
          } catch (err) {
            console.warn("Erreur lors de la récupération des factures", err);
          }
        },
        [dispatch, status, selectedDates]
      );

      useEffect(() => {
        loadFactures();
        return () => {
        
        };
      }, [loadFactures]);

    const title = 'Liste des écritures';
    const ecritures: object[] = [];
    factures.forEach((facture) => {
        if(facture.reglement) {
            const reglement = JSON.parse(JSON.stringify(facture.reglement));
            if(reglement.payments) {
                reglement.payments.forEach((payment:any) => {
                    let datePayment = new Date(payment.payment_date);
                    let datePaymentFr = datePayment.toLocaleDateString("fr");//+" "+datePayment.toLocaleTimeString("fr-FR");
                    ecritures.push({
                        dateReglement : datePaymentFr,
                        numeroFacture : facture.numeroFacture,
                        client: facture.client.name,
                        type: payment.payment_type,
                        bank : payment.ribBank,
                        montant : payment.montant + ' €'
                    });
                });
            }
        }
    });
    const onDateUpdate = (field: string, value: string) => {
        if (value !== selectedDates) dispatch(updateDate(value));
      };

    const columns: ColumnEntity[] = [
        { field: 'dateReglement', headerName: 'Date règlement', width: 150, sortable: true, resizable: true },
        { field: 'numeroFacture', headerName: 'N° Facture', width: 150, sortable: true, resizable: true },
        { field: 'client', headerName: 'Client', width: 400, sortable: true, resizable: true },
        { field: 'type', headerName: 'Type', width: 150, sortable: true, resizable: true },
        { field: 'bank', headerName: 'Banque', width: 150, sortable: true, resizable: true },
        { field: 'montant', headerName: 'Montant réglé', align:'right', width: 150, sortable: true, resizable: true },
      ];
 
    return (
        <>
            <ViewCard>
            <CustomTable
                data={ecritures}
                columns={columns}
                hover={false}
                onSelectRows={onSelectRows}
            />
            </ViewCard>
        </>
    );
}

export default ListeEcritureTable;
