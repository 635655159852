import { entrepriseEntityAdapter } from './entreprise.slice';
import { RootState } from '../../../_config/redux';
import { createSelector, EntityId } from '@reduxjs/toolkit';

const entrepriseSelector = entrepriseEntityAdapter.getSelectors<RootState>((state) => state.entreprises);

export const selectAllEntreprises = (state: RootState) => {
	const entreprisesList = entrepriseSelector.selectAll(state);
	return entreprisesList;
};

export const selectAllLabelsAndIds = createSelector([ selectAllEntreprises ], (entreprises) =>
	entreprises.map((e) => ({ id: e.id, label: e.name }))
);

export const selectEntreprisesBySearch = (state: RootState) => {
	const entreprises = selectAllEntreprises(state);

	const { search } = state.entreprises;
	return entreprises.filter((entreprise) => entreprise.name.toLowerCase().includes(search.toLowerCase()));
}

export const selectEntrepriseById = (id: EntityId) => (state: RootState) => entrepriseSelector.selectById(state, id);