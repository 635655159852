import React from 'react';
import { object, string, ref } from 'yup';
import { Field, Form, Formik } from 'formik';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import InterlocuteurEntity from '../../../../core/interlocuteur/entities';
import { roles } from '../../../common/_helpers';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { addOneUser } from '../../../../core/user/use-cases';
import { setSuccess } from '../../../../core/notification/notification.slice';

interface Props {
  interlocuteur: InterlocuteurEntity | undefined;
  open: boolean;
  onClose(): void;
}

const EntrepriseManageInterlocuteurUserDialog = ({ interlocuteur, open, onClose }: Props) => {
  const initialValues = {
    password: '',
    passwordConfirm: '',
    roles: 'client', // default value
  };

  const schema = object().shape({
    password: string().required('Mot de passe requis').min(8),
    passwordConfirm: string()
      .required()
      .oneOf([ref('password'), null]),
    roles: string().required('Veuillez choisir un rôle'),
  });

  const dispatch = useAppDispatch();

  const onSubmit = async ({ password, roles }: { password: string; roles: string }) => {
    const res: any = await dispatch(addOneUser({ interlocuteurId: interlocuteur?.id, password, roles }));

    if (!res.error) {
      dispatch(setSuccess(`Le compte de l'interlocuteur a bien été créé`));
      onClose();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Créer un compte utilisateur</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent sx={{ width: 400 }}>
                <Stack direction="column" gap={2}>
                  <TextField fullWidth disabled variant="standard" label="Nom" value={interlocuteur?.nom} />
                  <TextField fullWidth disabled variant="standard" label="Prénom" value={interlocuteur?.prenom} />
                  <TextField
                    fullWidth
                    disabled
                    variant="standard"
                    label="Employeur"
                    value={interlocuteur?.employeur.name}
                  />
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Mot de passe"
                    error={!!errors.password && !!touched.password}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.password && errors.password}
                    required
                    type="password"
                  >
                    {values.password}
                  </TextField>
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Confirmation mot de passe"
                    error={!!errors.passwordConfirm && !!touched.passwordConfirm}
                    name="passwordConfirm"
                    value={values.passwordConfirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.passwordConfirm && errors.passwordConfirm}
                    required
                    type="password"
                  >
                    {values.passwordConfirm}
                  </TextField>
                  <Field
                    component={TextField}
                    select={true}
                    fullWidth
                    required
                    variant="standard"
                    name="roles"
                    sx={{ mt: 1 }}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      setFieldValue('roles', value);
                    }}
                    value={values.roles}
                  >
                    {roles.map((role) => {
                      if (role.value !== 'admin') {
                        return (
                          <MenuItem key={role.value} value={role.value}>
                            {role.label}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Field>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Fermer</Button>
                <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!dirty || !isValid}>
                  Enregistrer
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EntrepriseManageInterlocuteurUserDialog;
