import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useAppDispatch } from '../../../../_config/react/useAppDispatch';
import { setSuccess } from '../../../../App/core/notification/notification.slice';
import { selectAllPaymentTypes } from '../../../../App/core/payment-type/selectors';
import { retrieveAllPaymentType } from '../../../../App/core/payment-type/use-cases';
import { validatePayment } from '../../../Domain/Application/use-cases/validate-payment';
import { Avoir, Facture } from '../../../Domain/Model';
import { CustomTable } from '../../../../App/app/common/custom-table';
import { ColumnEntity } from '../../../../App/app/common/custom-table/Table.entities';
import { EntityId } from '@reduxjs/toolkit';
import { avoirToUse } from '../../../Domain/Application/use-cases/use-avoir';
import { dateFormat } from '../../../../App/app/common/_helpers';
import { format } from 'date-fns';
import { selectAllribTypes } from '../../../../App/core/rib-type/selectors';
import { updateOneFacture } from '../../../Domain/Application/use-cases/update-one-bon';

interface Props {
  open: boolean;
  factures: Facture[] | undefined;
  totalAvoirs: number;
  avoirsList: Avoir[] | undefined;
  onCloseDialog(): void;
}

export const MultiFacturesValidatePaymentDialog = ({ open, factures, totalAvoirs, avoirsList, onCloseDialog }: Props) => {
  const [montant, setMontant] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [bank, setBank] = useState('');

  const [soldeChecked, setSoldeChecked] = useState(false);
  const [avoirsToUse, setAvoirsToUse] = useState<Avoir[]>();
  const [totalUsedAvoirs, setTotalUsedAvoirs] = useState(0);
  const [montantToPay, setMontantToPay] = useState(0);

  const [Lettrage, setLettrage] = useState('');

  const getSoldeClient = () => {
    const montant = factures?.map((facture,index) => {
      return index === 0 ? facture.client.solde_reglement_client : null; 
    });
    return montant ? montant : 0;
  }

  const dispatch = useAppDispatch();
  const paymentTypes = useSelector(selectAllPaymentTypes);
  const ribTypes = useSelector(selectAllribTypes);
 
  useEffect(() => {
    let defaultAmount = 0; 
    factures?.forEach((facture:any) => { 
      if(facture.totalTTC) {defaultAmount+=Number(facture.totalTTC); }
    });
    if(factures && sameRib(factures)) { 
      setBank(factures[0].rib ? factures[0].rib : ''); 
    }

    setMontant(""+defaultAmount);
    setMontantToPay(defaultAmount);
  }, [factures,totalAvoirs,avoirsList]);

  useEffect(() => {
    dispatch(retrieveAllPaymentType());
  }, [dispatch]);

  useEffect(() => {

  }, [totalUsedAvoirs,montant]);

  function sameRib(factures:Facture[]) {
    if (factures.some((facture:any) => !facture.rib && facture.rib !== '')) {
      return false;
    }else {
     if(factures && factures[0] && factures[0].rib) {
     
        const valeurRetour = factures[0].rib;
          return factures.reduce((acc, facture) => {
            if (facture.rib !== valeurRetour) {
              return false;
            }
            return acc;
          }, true);
      }
      else { return false;}
    }
  }
/*
  const defaultAmount = () => {
    let defaultAmount = 0; 
    factures?.forEach((facture:any) => { 
      defaultAmount+=facture.totalTTC; 
    });
    return defaultAmount;
  }*/
function updateAvoirs(avoir:any) {
  return dispatch(avoirToUse(avoir));
}

const onConfirmPaymentLettrage = () => {
  setLettrage("new");
  onConfirmPayment();
}

  const onConfirmPayment = async () => {

    let typePaiement = soldeChecked ? 'SOLDE' : paymentType;
    let avoirListToUse = JSON.parse(JSON.stringify(avoirsToUse?.sort((a, b) => a.created_at - b.created_at)));
    let montantDesAvoirs = Number(totalUsedAvoirs);
    let montantSaisie = Number(montant);
    let montantTotalRegle = montantDesAvoirs+montantSaisie;
    let resError:Boolean = false;
    let montantASolder = 0; 

    await factures?.sort(function(a,b){
      return Date.parse(b.paymentDueDate) - Date.parse(a.paymentDueDate);
    }).forEach(async (facture) => {

//typePaiement === 'SOLDE' || 

      if(montantDesAvoirs > 0) {
        
          // solder tout les avoirs
          avoirListToUse?.filter((a:any) => !a.used_at)?.map((s:any) => {
            if(montantDesAvoirs <= Number(facture.totalTTC)) {
              s.used_at = format(new Date(), dateFormat);
              s.facture_number = facture.id;
              montantDesAvoirs = montantDesAvoirs-Number(s.net_a_deduire.value);
              montantASolder+= Number(s.net_a_deduire.value);
              return updateAvoirs(s);
            } else return false;

          });
          typePaiement = 'AVOIRS';

      } else { 
        typePaiement = paymentType; 
        montantASolder+= montantTotalRegle-montantSaisie;
      }
   
      console.log(" id: "+facture.id+", montant: "+montantASolder+", paymentType: "+typePaiement+", bank: "+bank+", Lettrage : "+Lettrage);
      
      if(Lettrage === "new") {
        const reslettrage: any = facture?.id
        ? await dispatch(
            updateOneFacture({
              id: facture.id,
              lettrage: "new",
            })
          )
        : null;
      }

      const res: any = facture ? await dispatch(validatePayment({ id: facture.id, montant: ""+montantASolder, paymentType: typePaiement, bank: bank })) : null;
      if (!res?.error && resError === false) {  }
      else { resError = true; }
    });

    if(resError === false) {
      dispatch(setSuccess(`Le règlement a bien été validé`));
      onCloseDialog();
    }
  };


  const avoirsListColumns: ColumnEntity[] = [
    {
      field: "numero",
      headerName: "Avoir N°",
      width: 110,
      sortable: true,
      resizable: true,
    },
    {
      field: "totalTTCe",
      headerName: "TTC",
      width: 100,
      sortable: true,
      resizable: true,
    },  
  ];
  const handleAvoirsSelected = (selectedAvoirs:EntityId[]) => {
    
    const listAvoirToUse = avoirsList?.filter((a) => selectedAvoirs.includes(a._id));
    console.log(listAvoirToUse);
    setAvoirsToUse(listAvoirToUse);
    console.log("listAvoirToUse", listAvoirToUse);

/*
    if(listAvoirToUse && listAvoirToUse.length > 0) { 
      console.log("setSoldeChecked",soldeChecked);
      setSoldeChecked(true); }
      else { setSoldeChecked(false); }
      console.log("setSoldeChecked",soldeChecked);

*/

    let totalUsed = 0;
    avoirsList?.map((a) => { 
      if(selectedAvoirs.includes(a._id)) {
        return totalUsed+=Number(a.net_a_deduire.value);
      } else return totalUsed;
    });
    setTotalUsedAvoirs(totalUsed);
  }
 
  const tableAvoirs = JSON.parse(JSON.stringify(avoirsList));
  tableAvoirs.forEach((a:any) => {
    if(a.net_a_deduire.value) { a.totalTTCe = Number(a.net_a_deduire.value).toFixed(2) + " €";  }
    a.id = a._id;
  });

  return (
    <Dialog open={open}>
      <DialogTitle>Valider le règlement</DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={2} p={2} minWidth={300}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Montant"
            value={montant}
            onChange={(event) => setMontant(event.target.value)}
            sx={{ mb: 1 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <FormControl>
            <InputLabel id="paymentSelect">Type de paiement</InputLabel>
            <Select
              labelId="paymentSelect"
              required
              fullWidth
              variant="outlined"
              label="Type de paiement"
              value={paymentType}
              onChange={(event) => setPaymentType(event.target.value)}
            >
              {paymentTypes.map((p) => (
                <MenuItem key={p.code} value={p.code}>
                  {p.wording}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="bankSelect">Banque</InputLabel>
            <Select
              labelId="bankSelect"
              required
              fullWidth
              variant="outlined"
              label="Banque"
              value={bank}
              onChange={(event) => setBank(event.target.value)}
            >
              {ribTypes.map((r) => (
                <MenuItem key={r._id} value={r._id}>
                  {r.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Divider></Divider>
          <Typography variant="body2" color="#666767">
              {totalUsedAvoirs > 0 && <span><b>Total avoirs à utiliser : {totalUsedAvoirs} €</b><br/></span> }
              <b>Montant réglé par {paymentType}: {montant} €</b><br/>
              <b>TOTAL TTC : {(Number(totalUsedAvoirs)+Number(montant))} €</b> sur {montantToPay} €
            </Typography>
          <Divider></Divider>

          {Number(getSoldeClient) < 0 ? (
            <div>
              <FormControlLabel
                control={<Checkbox checked={soldeChecked} onChange={(e) => setSoldeChecked(e.target.checked)} />}
                label="Utiliser le solde trop-perçu"
              />
              <Divider></Divider>
            </div>
          ) : null}


          {Number(totalAvoirs) > 0 ? (
            <Typography variant="body2" color="#666767">
              Total avoirs client: {totalAvoirs} € <br/>
            </Typography>
          ) : null}   

          {Number(totalAvoirs) > 0 ? (
            <CustomTable
              data={tableAvoirs}
              columns={avoirsListColumns}
              onSelectRows={handleAvoirsSelected}
              height={Number(200)}
            ></CustomTable>
          ) : null}    


        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Fermer</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmPaymentLettrage}
          disabled={!(!!montant && (!!paymentType || soldeChecked))}
        >
          Valider et lettrer
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmPayment}
          disabled={!(!!montant && (!!paymentType || soldeChecked))}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};
