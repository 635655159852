/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, MouseEvent } from "react";
import { Badge, Button, IconButton, Stack, Tooltip } from "@mui/material";
import {
  Add,
  FolderZip,
  FileDownload,
  Tune as TuneIcon,
} from "@mui/icons-material";

import { FactureCreateDialog } from "../facture-create";
import { DateRangeField } from "../../../../../App/app/common/date-picker";
import { CustomTableToolbar } from "../../../../../App/app/common/custom-table";
import { useAppDispatch } from "../../../../../_config/react/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../_config/redux";
import {
  updateDate,
  updateStatus,
} from "../../../../Domain/Application/facture.slice";
//import DropDownButton from "../../../../App/app/common/DropDownButton";
import {
  factureStatus,
  exportToXLSX,
  dateFormat
} from "../../../../../App/app/common/_helpers";
import { FactureRelanceDialog } from "../../commun";
import { EntityId } from "@reduxjs/toolkit";
import { getZipOfAllDocumentsOfFacture } from "../../../../../App/infra/api/facture.api-service";
import { setError } from "../../../../../App/core/notification/notification.slice";
//import { ClientFournisseurRadioSelect } from "../ClientFournisseurRadioSelect";
import { selectAllFactures } from "../../../../Domain/Application/selectors";
import { format } from 'date-fns';
//import { Link } from 'react-router-dom';
import FactureSearchPopover from "../facture-filters/FactureSearchPopover";
interface Props {
  selectedRows: EntityId[];
  type: "client" | "fournisseur" | "";
  /*selectedType: "fournisseur" | "client" | "";
  filterType: (type: "fournisseur" | "client" | "") => void;*/
}

function FactureTableToolbar({
  selectedRows,
  type
 /* filterType,
  selectedType,*/
}: Props) {

  
  const dispatch = useAppDispatch();
  const selectedDates = useSelector((state: RootState) => state.factures.dates);
  const [filtersFactureBadge, setfiltersFactureBadge] = useState(0);
  const filters: any = useSelector((state: RootState) => state.factures.filters);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [relanceDialogOpen, setRelanceDialogOpen] = useState(false);
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false);
  const [searchAnchorEl, setSearchAnchorEl] = useState<Element | null>(null);

  const onDateUpdate = (field: string, value: string) => {
    if (value !== selectedDates) dispatch(updateDate(value));
  };

  const handleRelanceDialog = () => setRelanceDialogOpen(!relanceDialogOpen);

  const downloadZipFileForSelection = async () => {
    try {
      const zips = await Promise.all(
        selectedRows.map((id) => getZipOfAllDocumentsOfFacture(id))
      );
      zips.forEach((zip) => {
        const url = window.URL.createObjectURL(zip);
        window.open(url); //, '_blank')
      });
    } catch (e) {
      dispatch(
        setError({
          message: "un problème est survenu lors de l'export du fichier Zip",
        })
      );
    }
  };

  const handleCreateDialog = () => setIsCreateDialogOpen(!isCreateDialogOpen);

  //const onChangeFilter = (selected: string) => dispatch(updateStatus(selected));
  const factures = useSelector(selectAllFactures).filter((facture) => facture.type === type && facture.numeroFacture != '');
  const getStatusOfFactures = (status: string) => {
    switch (status) {
      case "CREATED":
        return "Nouvelle facture";
      case "EMITTED":
        return "Facture émise";
      case "EDITED":
        return "Facture éditée";
      case "PAID":
        return "Facture payée";
      case "CANCELLED":
        return "Facture annulée";
      default:
        return null;
    }
  };
 



  const onClickExport = () => {
    const toExport = factures.map(({ id, ...otherProps }) => otherProps);
    const excelLine = toExport.map((factures) => {
      return {
        numero: factures.numeroFacture,
        status: getStatusOfFactures(factures.status),
        date: factures.dateDeFacturation,
        client: factures.client.name,
        clientType: factures.type,
        nbLignes: factures.bons?.length,
        totalFraisAuto: factures.totalFraisAutoroute,
        totalTva: factures.totalTva,
        totalTTC: factures.totalTTC,
        totalEncaisse: factures.reglement.montant,
      };
    });
    const heading = [
      "N° Facture",
      "Status",
      "Date",
      "Client",
      "Type client",
      "Nb Lignes",
      "Total Frais Auto",
      "total TVA",
      "Total TTC",
      "Total Encaissé",
    ];
    let export_dates = format(new Date(), dateFormat);
    if(selectedDates != "") {
      export_dates = selectedDates;
    }
    exportToXLSX(excelLine, `Factures-${export_dates}`, heading);
  };

  useEffect(() => {
    let badgeCounter = 0;
    for (const property in filters) {
        if ( filters[property] !== '' && filters[property] !== 0) {
            badgeCounter++;
        }
    }
    setfiltersFactureBadge(badgeCounter);
}, [filters]);

  const onOpenSearchPopover = (event: MouseEvent) => {
    const { currentTarget } = event;
    setIsSearchPopoverOpen(true);
    setSearchAnchorEl(currentTarget);
  };
  const onCloseSearchPopover = () => {
    setIsSearchPopoverOpen(false);
    setSearchAnchorEl(null);
  };


  return (
    <>
      <CustomTableToolbar>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <DateRangeField
            sx={{ width: 270 }}
            size="small"
            variant="outlined"
            label="Filtrer par date..."
            onDateUpdate={onDateUpdate}
            value={selectedDates}
          />

          {/* Buttons */}
          <Tooltip title="Filtrer">
            <IconButton onClick={onOpenSearchPopover}>
              <Badge badgeContent={filtersFactureBadge} color="error">
                  <TuneIcon/>
              </Badge>
            </IconButton>
          </Tooltip>

          {/*<ClientFournisseurRadioSelect
            onChange={(e) => filterType(e.target.value as any)}
            selectedValue={selectedType}
          />*/}

          {/* Buttons 
          
          <DropDownButton
            labels={[
              { label: "Toutes les factures", value: "" },
              ...factureStatus,
            ]}
            onChange={onChangeFilter}
          />

           <Button
            size="small"
            variant="contained"
            color="warning"
            startIcon={<PriorityHigh />}
            onClick={handleRelanceDialog}
          >
            Relances
          </Button>
        
          <Button 
            component={Link} 
            size="small"
            to="ecriture" 
            variant="contained" 
            color="secondary" 
            startIcon={<Edit/>}>
                    Ecritures
          </Button>
          
          */}
          

         
         
          <Button
            size="small"
            variant="contained"
            color="info"
            startIcon={<FolderZip />}
            onClick={downloadZipFileForSelection}
            disabled={selectedRows.length === 0}
          >
            Zip
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={onClickExport}
            startIcon={<FileDownload />}
            size="small"
          >
            XLSX
          </Button>
        </Stack>
        {/*<Stack>*/}
        {/*</Stack>*/}
        <Stack direction="row" spacing={4} alignItems="end">
          <Button
            onClick={handleCreateDialog}
            variant="contained"
            color="secondary"
            startIcon={<Add />}
          >
            {type === "client" && ("Nouvelle Facture") }
            {type === "fournisseur" && ("Nouveau bon de commande") }
          </Button>
        </Stack>
      </CustomTableToolbar>

      {/* Dialogs */}
      <FactureRelanceDialog
        open={relanceDialogOpen}
        onClose={handleRelanceDialog}
      />
      <FactureCreateDialog
        open={isCreateDialogOpen}
        handleDialog={handleCreateDialog}
        type={type}
      />

      <FactureSearchPopover
                open={isSearchPopoverOpen}
                anchorEl={searchAnchorEl}
                onCloseSearchPopover={onCloseSearchPopover}
                type={type}
            />

    </>
  );
}

export default FactureTableToolbar;
