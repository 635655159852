import React from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../../../../_config/react/useAppDispatch';
import { addOnePaymentType, updateOnePaymentType } from '../../../../core/payment-type/use-cases';
import { setSuccess } from '../../../../core/notification/notification.slice';

const PaiementTableAddDialog = ({ open, onCloseDialog, initialValues, typeId }: any) => {
  const schema = object().shape({
    code: string().required(),
    wording: string().required(),
    type: string().required(),
  });

  const dispatch = useAppDispatch();

  const onSubmit = async (values: any) => {
    let res: any;

    if (!!typeId) {
      res = await dispatch(updateOnePaymentType({ id: typeId, ...values }));
    } else {
      res = await dispatch(addOnePaymentType(values));
    }

    if (!res.error) {
      dispatch(setSuccess(!!typeId ? `Le type a bien été mis à jour` : `Le mode de paiement a bien été créé`));
      onCloseDialog();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{`${!!typeId ? 'Modifier' : 'Ajouter'} un mode de paiement`}</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => {
          const { values, touched, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, isSubmitting } =
            props;
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <DialogContent sx={{ minWidth: 400 }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Code"
                        error={!!errors.code && !!touched.code}
                        name="code"
                        value={values.code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.code && errors.code}
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Libellé"
                        error={!!errors.wording && !!touched.wording}
                        name="wording"
                        value={values.wording}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.wording && errors.wording}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Type"
                        error={!!errors.type && !!touched.type}
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.type && errors.type}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCloseDialog}>Annuler</Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    disabled={!dirty || !isValid}
                  >
                    Confirmer
                  </LoadingButton>
                </DialogActions>
              </Form>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default PaiementTableAddDialog;
